// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tutti-html-js": () => import("./../../../src/pages/blog/tutti.html.js" /* webpackChunkName: "component---src-pages-blog-tutti-html-js" */),
  "component---src-pages-funzioni-js": () => import("./../../../src/pages/funzioni.js" /* webpackChunkName: "component---src-pages-funzioni-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jsx-compiler-html-js": () => import("./../../../src/pages/jsx-compiler.html.js" /* webpackChunkName: "component---src-pages-jsx-compiler-html-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-libri-error-decoder-html-js": () => import("./../../../src/pages/libri/error-decoder.html.js" /* webpackChunkName: "component---src-pages-libri-error-decoder-html-js" */),
  "component---src-pages-ringraziamenti-html-js": () => import("./../../../src/pages/ringraziamenti.html.js" /* webpackChunkName: "component---src-pages-ringraziamenti-html-js" */),
  "component---src-templates-articoli-js": () => import("./../../../src/templates/articoli.js" /* webpackChunkName: "component---src-templates-articoli-js" */),
  "component---src-templates-biografia-js": () => import("./../../../src/templates/biografia.js" /* webpackChunkName: "component---src-templates-biografia-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-codepen-example-js": () => import("./../../../src/templates/codepen-example.js" /* webpackChunkName: "component---src-templates-codepen-example-js" */),
  "component---src-templates-libri-js": () => import("./../../../src/templates/libri.js" /* webpackChunkName: "component---src-templates-libri-js" */)
}

